import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import '../scss/wp-chat.scss';

const WpChat = () => {
  const renderTooltip = (props) => (
    <Tooltip id="whatsapp-tooltip" {...props}>
      Hit me in WhatsApp!
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 400 }}
        overlay={renderTooltip}
      >
        <div className="whatsapp">
          {/* <div className="whatsapp-text">Have a Question ?</div> */}
          <div className="whatsapp-icon">
            <a className='whatsapp-a' href="https://wa.me/+918099990963?text=" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </OverlayTrigger>
    </>
  );
};

export default WpChat;
