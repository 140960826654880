import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/autoplay';
import '../scss/skills.scss'
import PythonImage from '../images/skills/python-5.svg';
import NextJS from '../images/skills/nextjs.svg';
import ReactJs from '../images/skills/react-2.svg';
import Django from '../images/skills/django-community.svg';
import Wordpress from '../images/skills/wordpress-icon.svg';
import Js from '../images/skills/javascript-1.svg';
import Php from '../images/skills/php2-svgrepo-com.svg';
import HtmlImg from '../images/skills/html-1.svg';
import CssImage from '../images/skills/css-3.svg';
import BootstrapImg from '../images/skills/bootstrap-5-1.svg';
import JavaImg from '../images/skills/java-4.svg';
// import AI from '../images/skills/ai.svg';
import MysqlImg from '../images/skills/mysql-2.svg';
import MongoDB from '../images/skills/mongodb-icon-2.svg';
import PostmanImg from '../images/skills/postman.svg';
import GitBash from '../images/skills/git-bash.svg';


const Skills = () => {
  return (
    <>
        <div className='skills-sec desk-skills-sec'>
            <h2 className='skills-title'>core competencies</h2>
            <div className='container skills-sec-container'>
            <Swiper
                modules={[Autoplay]}
                spaceBetween={0}
                slidesPerView={6}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                // loopFillGroupWithBlank={true} // Ensure groups are filled with blank spaces if not enough slides
                speed={5000} // Adjust speed for continuous effect
                autoplay={{
                    delay: 1, // Delay between transitions (in ms)
                    disableOnInteraction: false, // Autoplay will not be disabled after user interactions
                  }}
                  freeMode={true} // Enable free mode for continuous scrolling effect
                  // freeModeMomentum={true} // Enable momentum for smooth scrolling
                  breakpoints={{
                    220: {
                      slidesPerView: 2,
                    },
                    330: {
                      slidesPerView: 3,
                    },
                    640: {
                      slidesPerView: 4,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    992: {
                      slidesPerView: 5, // Display 1.5 slides on screens >= 992px wide (desktops)
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                  }}
                >
                <SwiperSlide><img className='skill-image' src={PythonImage} alt="python" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={NextJS} alt="NextJS" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={ReactJs} alt="ReactJs" /></SwiperSlide>
                <SwiperSlide><img className='skill-image django-img' src={Django} alt="Django" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={Wordpress} alt="Wordpress" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={Js} alt="Js" /></SwiperSlide>
                <SwiperSlide><img className='skill-image php-img' src={Php} alt="Php" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={HtmlImg} alt="HtmlImg" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={CssImage} alt="CssImage" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={JavaImg} alt="JavaImg" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={MysqlImg} alt="MysqlImg" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={MongoDB} alt="MongoDB" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={PostmanImg} alt="PostmanImg" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={GitBash} alt="GitBash" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={BootstrapImg} alt="BootstrapImg" /></SwiperSlide>
                {/* <SwiperSlide><img className='skill-image' src={AI} /></SwiperSlide> */}
            </Swiper>
            </div>
        </div>
        


        {/* mobile view */}
        <div className='mobile-skills-sec d-none'>
            <h2 className='skills-title'>core competencies</h2>
            <div className='skills-sec-container'>
            <Swiper
                modules={[Autoplay]}
                spaceBetween={0}
                slidesPerView={6}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                loop={true}
                // loopFillGroupWithBlank={true} // Ensure groups are filled with blank spaces if not enough slides
                speed={5000} // Adjust speed for continuous effect
                autoplay={{
                    delay: 1, // Delay between transitions (in ms)
                    disableOnInteraction: false, // Autoplay will not be disabled after user interactions
                  }}
                  freeMode={true} // Enable free mode for continuous scrolling effect
                  // freeModeMomentum={true} // Enable momentum for smooth scrolling
                  breakpoints={{
                    220: {
                      slidesPerView: 2,
                    },
                    330: {
                      slidesPerView: 3,
                    },
                    640: {
                      slidesPerView: 4,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    992: {
                      slidesPerView: 5, // Display 1.5 slides on screens >= 992px wide (desktops)
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                  }}
                >
                <SwiperSlide><img className='skill-image' src={PythonImage} alt="python"/></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={NextJS} alt="NextJS" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={ReactJs} alt="ReactJs" /></SwiperSlide>
                <SwiperSlide><img className='skill-image django-img' src={Django} alt="Django" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={Wordpress} alt="Wordpress" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={Js} alt="Js" /></SwiperSlide>
                <SwiperSlide><img className='skill-image php-img' src={Php} alt="Php" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={HtmlImg} alt="HtmlImg" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={CssImage} alt="CssImage" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={JavaImg} alt="JavaImg" /></SwiperSlide>
                <SwiperSlide><img className='skill-image mysql' src={MysqlImg} alt="MysqlImg" /></SwiperSlide>
                <SwiperSlide><img className='skill-image mongo' src={MongoDB} alt="MongoDB" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={PostmanImg} alt="PostmanImg" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={GitBash} alt="GitBash" /></SwiperSlide>
                <SwiperSlide><img className='skill-image' src={BootstrapImg} alt="BootstrapImg" /></SwiperSlide>
                {/* <SwiperSlide><img className='skill-image' src={AI} /></SwiperSlide> */}
            </Swiper>
            </div>
        </div>
    </>
  )
}

export default Skills
