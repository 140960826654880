import React from 'react'
import '../scss/freelance.scss'
function Freelance({contactSecRef}) {
  const handleScrollToContact = () => {
    contactSecRef.current.scrollIntoView({behavior:'smooth'})
  }
  return (
    <>
      <div className='freelance-sec'>
        <main className=' container free-container'>
          <div className='free-d-flx'>

            <h3 className='freelance-text'>I'm available for freelance projects in          </h3>
            <section className="animation">
                <div><div className="span-text">WordPress</div></div>
                <div><div className="span-text">Next.js</div></div>
                <div><div className="span-text">React.js</div></div>
                <div><div className="span-text">Django</div></div>
              </section>
          </div>
          <div className='contact-btn-sec'>
            <div className="container">
              <a onClick={handleScrollToContact}  className="contact-button type--A">
                <div className="button__line"></div>
                <div className="button__line"></div>
                <span className="button__text">Contact Me</span>
              </a>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Freelance
