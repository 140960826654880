import React, { useState, useEffect, useRef } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../scss/about.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

function About() {
  const [isVisible, setIsVisible] = useState(false);
  const aboutRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Intersection ratio that triggers the callback
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once the section is visible
        }
      });
    }, options);

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    // Clean up observer
    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);

  return (
    <>
      <div >
        <section className={`about-sec ${isVisible ? 'visible' : ''}`} ref={aboutRef}>
          <Row className='about_title_row'>
            <Col>
              <h3 className='hello-text'>HELLO THERE</h3>
              <h2 className='about_title'>I Am Vamshu</h2>
              {/* <span>Quick learner, Innovator, Coding Enthusiast</span> */}
            </Col>
          </Row>
          <Row className='about_title_row2'>
            <Col className='about_content'>
              <p>Dedicated and results-driven software developer with three years of hands-on
                experience in developing, and maintaining high-quality software
                solutions. Proficient in a wide range of programming languages and
                technologies, with a focus on creating efficient and scalable applications. Adept
                at collaborating with cross-functional teams to deliver software projects that
                meet or exceed client and end-user expectations. Strong problem-solving skills
                and a commitment to staying current with emerging technologies, making
                valuable contributions to projects that drive innovation and business success.</p>
            </Col>
          </Row>
          <div className="about__line"></div>
        </section>

      </div>
    </>
  )
}

export default About
