import React from 'react'
import '../scss/projects.scss'
import ImageNotFound from '../images/Image-not-found.png'
import Medvarsity from '../images/medvarsity-site.png'
import Assimilate from '../images/assimilate-site.png'
import Healthjobs from '../images/healthjobs-site.png'
import Thankanurse from '../images/thankanurse-site.png'
import Dosily from '../images/dosily-site.png'
import Bookmysim from '../images/bookmysim-site.png'
import Staffington from '../images/staffington.png'
import Repairforce from '../images/repairforce.png'
function Projects() {
    return (
        <>
            <div className='projects'>
                <div className="container ">
                    <div className="projectbtns_sec">
                        <div className="pro_btn_tab">

                        <ul className="nav project-nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item proBtn" role="presentation">
                                <button className="nav-link active position-relative" id="pills-projects-tab" data-bs-toggle="pill" data-bs-target="#pills-projects" type="button" role="tab" aria-controls="pills-projects" aria-selected="true">Projects</button>
                            </li>
                            <li className="nav-item proBtn" role="presentation">
                                <button className="nav-link position-relative" id="pills-freelance-tab" data-bs-toggle="pill" data-bs-target="#pills-freelance" type="button" role="tab" aria-controls="pills-freelance" aria-selected="false">Freelance</button>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="tab-content p-3 text-danger" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-projects" role="tabpanel" aria-labelledby="pills-projects-tab">
                            <div className="row row-cols-1 row-cols-md-3 g-5">
                                <div className="col project_col_card">
                                    <a href="https://www.medvarsity.com/" target='blank'>
                                        <div className="card projectCards">
                                            <img src={Medvarsity} className="card-img-top" alt="medvarsity online limited" />
                                            {/* <div className="card-body">
                                                <h5 className="card-title">Card title</h5>
                                                <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            </div> */}
                                            <div className="overlay">
                                                <div className="text">Medvarsity.Com</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col project_col_card">
                                    <a href="https://assimilate.one/" target='blank'>
                                    <div className="card projectCards">
                                        <img src={Assimilate} className="card-img-top" alt="assimilate" />
                                        {/* <div className="card-body">
                                            <h5 className="card-title">Card title</h5>
                                            <p className="card-text">This is a short card.</p>
                                        </div> */}
                                        <div className="overlay">
                                            <div className="text">Assimilate.One</div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col project_col_card">
                                <a href="https://healthjobs.one/" target='blank'>
                                    <div className="card projectCards">
                                        <img src={Healthjobs} className="card-img-top" alt="healthjobs" />
                                        {/* <div className="card-body">
                                            <h5 className="card-title">Card title</h5>
                                            <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
                                        </div> */}
                                        <div className="overlay">
                                            <div className="text">HealthJobs.One</div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col project_col_card">
                                <a href="https://thankanurse.one/" target='blank'>
                                    <div className="card projectCards">
                                        <img src={Thankanurse} className="card-img-top" alt="thankanurse" />
                                        {/* <div className="card-body">
                                            <h5 className="card-title">Card title</h5>
                                            <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        </div> */}
                                        <div className="overlay">
                                            <div className="text">ThankANurse.One</div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col project_col_card">
                                <a href="https://dosily.com/" target='blank'>
                                    <div className="card projectCards">
                                        <img src={Dosily} className="card-img-top" alt="dosily" />
                                        {/* <div className="card-body">
                                            <h5 className="card-title">Card title</h5>
                                            <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        </div> */}
                                        <div className="overlay">
                                            <div className="text">Dosily.Com</div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col project_col_card">
                                <a href="https://bookmysim.one/" target='blank'>
                                    <div className="card projectCards">
                                        <img src={Bookmysim} className="card-img-top" alt="bookmysim" />
                                        {/* <div className="card-body">
                                            <h5 className="card-title">Card title</h5>
                                            <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        </div> */}
                                         <div className="overlay">
                                            <div className="text">BookMySim.One</div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-freelance" role="tabpanel" aria-labelledby="pills-freelance-tab">
                            <div className="row row-cols-1 row-cols-md-3 g-5">
                                <div className="col project_col_card">
                                <a href="https://staffington.net/" target='blank'>
                                    <div className="card projectCards">
                                        <img src={Staffington} className="card-img-top" alt="staffington" />
                                        
                                        <div className="overlay">
                                            <div className="text">Staffington.net</div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col project_col_card">
                                <a href="https://repairforce.in/" target='blank'>
                                    <div className="card projectCards">
                                        <img src={Repairforce} className="card-img-top" alt="repairforce" />
                                        
                                        <div className="overlay">
                                            <div className="text">Repairforce.in</div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                                <div className="col project_col_card">
                                <a href="https://fixifuse.com/" target='blank'>
                                    <div className="card projectCards">
                                        <img src={ImageNotFound} className="card-img-top" alt="fixifuse" />
                                        <div className="card-body">
                                            <h5 className="card-title text-light">FixiFuse</h5>
                                            <p className="card-text text-light">Working in progress.<br/><br/><br/><br/><br/><br/></p>
                                            
                                        </div>
                                        <div className="overlay">
                                            <div className="text">Fixifuse.com</div>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default Projects
