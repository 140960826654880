import { Row, Col } from 'react-bootstrap';
// import  Col /from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import '../scss/modalpopup.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
// import {  } from 'swiper/modules';// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/pagination'; // Ensure you import pagination styles
// import 'swiper/scss/navigation'; // Ensure you import navigation styles
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

function ModelPopup({show, handleClose}) {
    const [currentTab, setCurrentTab] = useState('Internship ');

    const readMore = (tabName) => {
      setCurrentTab(tabName);
    };
  
  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
            Read More About Me!
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
         <Row>
         <Col lg className=" mt-3 moregap morepop-up">
                
                <div className="tab">
                  <button className={`tablinks ${currentTab === 'Internship ' ? 'active' : ''}`} onClick={() => readMore('Internship ')}>
                  Internship 
                  </button>
                  <button className={`tablinks ${currentTab === 'Education' ? 'active' : ''}`} onClick={() => readMore('Education')}>
                  Education
                  </button>
                  <button className={`tablinks ${currentTab === 'Hobbies' ? 'active' : ''}`} onClick={() => readMore('Hobbies')}>
                  Hobbies
                  </button>
                </div>

                {/* Tab content */}
                <div id="Internship " className={`tabcontent ${currentTab === 'Internship ' ? 'show' : 'd-none'}`}>
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  pagination={{
                    clickable:true
                  }}
                  scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log('slide change')}
                  loop={true}
                  speed={4000} // Adjust speed for continuous effect
                  autoplay={{
                      delay: 3000, // Delay between transitions (in ms)
                      // disableOnInteraction: false, // Autoplay will not be disabled after user interactions
                    }}
                  freeMode={true}
                >
                  <SwiperSlide>
                    <h6 className='modal-h6'>Symposium, Online-Internship</h6>
                    <span className='modal-date'>MARCH, 2021</span>
                    <p className='modal-txt'> Certified in Python Programming, having completed my certification at Symposium. This program has provided me with a strong foundation in Python, enabling me to write efficient, clean, and maintainable code. I am well-versed in various Python libraries and frameworks, preparing me to tackle a wide range of programming challenges.</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h6 className='modal-h6'>Symposium, Online-Internship</h6>
                    <span className='modal-date'>JUNE, 2021</span>
                    <p className='modal-txt'>certified in Artificial Intelligence, having completed my certification at Symposium. This program has given me a deep understanding of AI principles, including machine learning, neural networks, and natural language processing.</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h6 className='modal-h6'>Kodnest, Online-Internship</h6>
                    <span className='modal-date'>JULY 16, 2021</span>
                    <p className='modal-txt'>Certified Java Programmer and Full Stack Developer, having completed my training at Kodnest. This comprehensive program has equipped me with the skills to develop robust, scalable applications, covering both front-end and back-end technologies. I am proficient in Java, along with modern frameworks and tools essential for full stack development.</p>
                  </SwiperSlide>
                </Swiper>
                </div>

                <div id="Education" className={`tabcontent ${currentTab === 'Education' ? 'show' : 'd-none'}`}>
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log('slide change')}
                  loop={true}
                  speed={4000} // Adjust speed for continuous effect
                  autoplay={{
                      delay: 3000, // Delay between transitions (in ms)
                      // disableOnInteraction: false, // Autoplay will not be disabled after user interactions
                    }}
                  freeMode={true}
                >
                  <SwiperSlide>
                    <h6 className='modal-h6'> Bachelor of Technology - Computer Science Engineering</h6>
                    <span className='modal-date'>2017-2021</span>
                    <p className='modal-txt'>I graduated with a degree in Computer Science and Engineering from <b>BRILLIANT GRAMMAR SCHOOL EDUCATIONAL SOCIETY'S GROUP OF INSTITUTIONS - Hyderabad.</b></p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h6 className='modal-h6'>Intermediate - MPC</h6>
                    <span className='modal-date'>2015-2017</span>
                    <p className='modal-txt'> I hold an  Intermediate education at <b>S.R. Junior College - Hyderabad</b>. This stage of my education laid the groundwork in science and mathematics, preparing me for advanced studies in computer science and engineering.</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h6 className='modal-h6'>SSC</h6>
                    <span className='modal-date'>2015</span>
                    <p className='modal-txt'>I completed my SSC at <b>Telangana state Residential School</b>. This foundational education equipped me with essential knowledge and skills in various subjects, laying the groundwork for my future academic and professional pursuits.</p>
                  </SwiperSlide>
                </Swiper>
                </div>

                {/* Hobbies */}
                <div id="Hobbies" className={`tabcontent ${currentTab === 'Hobbies' ? 'show' : 'd-none'}`}>
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log('slide change')}
                  loop={true}
                  speed={4000} // Adjust speed for continuous effect
                  autoplay={{
                      delay: 3000, // Delay between transitions (in ms)
                      // disableOnInteraction: false, // Autoplay will not be disabled after user interactions
                    }}
                  freeMode={true}
                >
                  <SwiperSlide>
                    <h6 className='modal-h6'> Gym</h6>
                    {/* <span className='modal-date'>2017-2021</span> */}
                    <p className='modal-txt'>Strength will give you the confidence to be ready for any situation. Be strong both physically and mentally.</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h6 className='modal-h6'>Playing Guitar</h6>
                    <p className='modal-txt'> The feeling that can be expressed without words. <br/>don't listen, just feel it.</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h6 className='modal-h6'>Dancing</h6>
                    <p className='modal-txt'>Movement speaks louder than words.</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h6 className='modal-h6'>Painting</h6>
                    <p className='modal-txt'>Colors blend into imagination.</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h6 className='modal-h6'>Reading Books</h6>
                    <p className='modal-txt'>My favorite book is <b>"THINK LIKE A MONK" <span className='modal-date'>- Jay Shetty</span></b>.</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h6 className='modal-h6'>Coding</h6>
                    <p className='modal-txt'>I enjoy coding, regardless of the tools or technologies used.</p>
                  </SwiperSlide>
                </Swiper>
                </div>

              </Col>
         </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModelPopup;