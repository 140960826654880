import React from 'react';
import '../scss/bannersection.scss';
import logo from '../images/vamshu-logo2.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const BannerSection = ({ profileSecRef, contactSecRef }) => {
    const handleScrollToProfile = () => {
        profileSecRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const handleScrollToContact = () => {
        contactSecRef.current.scrollIntoView({behavior:'smooth'})
      }
    return (
        <>

            <div className='banner_section'>
                <div className='banner_content'>

                    <div className="header-logo">
                        <a className="site-logo" href="index.html">
                            <img src={logo} alt="Homepage" />
                        </a>
                    </div>

                    <Container className='banner-content-container'>

                        <div className='banner-heading'>
                            <Row>
                                <Col sm={8} className='banner-right-content'>
                                    <h3>WELCOME</h3>
                                    <h1>I'm embraces challenges, seeks solutions and elevates each day by turning dreams into reality, byte by byte.</h1>

                                    <div className='home-content__buttons'>
                                        <button onClick={handleScrollToContact} className="smoothscroll btn btn--stroke"> Start a Project </button>
                                        <button onClick={handleScrollToProfile} className="smoothscroll btn btn--stroke more-about-btn"> More About Me </button>

                                    </div>
                                    
                                
                                </Col>
                                <Col sm={4} className='social-icons'>
                                    <ul>
                                        <li><a href='https://www.facebook.com/vamshuch.vamshuch' target='blank'><i className="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href='https://www.linkedin.com/in/vamshu-ch-23b24a215' target='blank'><i className="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href='https://www.instagram.com/vamshu_ch/' target='blank'><i className="fa-brands fa-instagram"></i></a></li>
                                        <li><a href='mailto:vamshuch@gmail.com' target='blank' ><i className="fa-brands fa-google"></i></a></li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <div className="home-content__line"></div>

                </div>


            </div>
        </>
    )
}

export default BannerSection;
