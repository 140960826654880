import '../src/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// import Container from 'react-bootstrap/Container';
import React, { useRef } from 'react';

import BannerSection from './components/BannerSection';
import About from './components/About';
import Profile from './components/Profile';
import Freelance from './components/Freelance';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';
import WpChat from './components/WpChat';
import WaveFooter from './components/WaveFooter';
import '../src/scss/mobile-resolution.scss';


function App() {
  const profileSecRef = useRef(null);
  const contactSecRef = useRef(null);

  return (
    <div className="App">
      <BannerSection  profileSecRef={profileSecRef} contactSecRef={contactSecRef}/>
      <About />
      <Profile profileSecRef={profileSecRef}/>
      <Freelance contactSecRef={contactSecRef}/>
      <Projects />
      <Skills/>
      <Contact contactSecRef={contactSecRef} />
      <WpChat/>
      <WaveFooter/>
    </div>
  );
}

export default App;
