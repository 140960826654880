import React, {useState}from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../scss/profile.scss';
import ModalPopup from './ModalPopup'
import ProfileImg from '../images/profile-blackwhite.png';
import ResumePdf from '../pdfs/Resume of Vamshi Krishna.pdf';

function Profile({ profileSecRef }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false); //callback function 

  return (
    <>
    <section ref={profileSecRef} className='profile-sec' id="profile-sec">
        <Container>
            <Row>
                <Col lg={5}>
                    <div className='image-container'>
                        <div className='image-bg'></div>
                        <div className='image-area'>
                            <img src={ProfileImg} alt='profile'/>
                        </div>

                    </div>
                </Col>
                <Col lg={7} className='profile-text-col'>
                <div className="profile-title left">
                    <p className="sub-title">About <span className='me'>me</span></p>
                    <h2 className='name-title'>Vamshi Krishna Ch</h2>
                    <div className='name-title-line'></div>
                </div>
                <p className='aboutme-para'>Hello! I'm Vamshi Krishna. I'm a developer, and I'm very passionate and dedicated to my work. With 3 years experience as a professional software and web developer, I have acquired the skills and knowledge necessary to make your project a success. I enjoy every step of the design process, from discussion and collaboration.</p>
                <div className='d-flex profile-btns'>
                    <div className='readmore btn'><button onClick={() => setShow(true)}>READ MORE</button></div>
                    <div className='download-cv btn'><a href={ResumePdf} target='_blank'  rel="noreferrer">DOWNLOAD CV</a></div>
                </div>
                </Col>
            </Row>

        </Container>
    </section>
    <ModalPopup show={show} handleClose={handleClose}/>
    </>
  )
}

export default Profile
